.b-account_bg {
	background-color: $color-bg-ma;
	border: $global-line solid $color-divider;
	border-radius: $global-radius;
	box-shadow: $depth-1;
	margin: 0 auto;
	overflow: hidden;
	padding: 40px 20px;
	position: relative;

	@include media(lg-up) {
		max-width: 785px;
	}

	&-logo {
		margin-bottom: 20px;

		@include media(lg-up) {
			margin-bottom: 26px;
		}
	}

	&-image {
		margin: 0 auto;

		@include media(md-down) {
			max-width: 145px;
		}
	}

	&-title {
		color: $color-title;
		font-size: 17px;
		font-weight: 700;
		margin-bottom: 20px;
		text-align: center;
	}

	&.m-login {
		padding-top: 20px;
	}

	&::before,
	&::after {
		background-size: cover;
		content: '';
		position: absolute;
	}

	&::before {
		background: url('../images/ma/knitting.png') no-repeat;
		background-size: 85%;
		height: 115px;
		left: 0;
		top: 19px;
		width: 74px;

		@include media(sm) {
			background-size: 60%;
			top: 9px;
		}
	}

	&::after {
		background: url('../images/ma/semicircle.png') no-repeat;
		background-position-y: 100%;
		bottom: 0;
		height: 85px;
		right: 27px;
		width: 146px;

		@include media(sm) {
			background-size: 100%;
			bottom: -15px;
			height: 73px;
			right: 19px;
			width: 73px;
		}
	}

	&.m-club_register {
		background: $color-bg-ma-club;
		background-size: 515px auto;
		max-width: 912px;
		padding-top: 20px;

		@include media(lg-up) {
			background-size: 912px auto;
			padding: 60px 200px 40px;
		}

		&::before,
		&::after {
			display: none;
		}
	}
}
